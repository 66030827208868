<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-square-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
      </symbol>
      <symbol id="exclamation-square-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
      </symbol>
    </svg>
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="xl"
      :close-on-backdrop="false"
      @update:show="closeModal"
      :show.sync="showModal"
    >
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
      <CRow>
        <div class="col-sm-12 col-lg-11">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="6" class="center-field">
              <div class="form-group form-row mb-0" rol="group">
                <label class="col-form-label-sm text-right required col-sm-11 col-lg-4 mb-0 requiered">
                  {{$t('label.dateFrom')}}
                </label>
                <div class="col-sm-12 col-lg-8 input-group">
                  <vue-datepicker
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="dateBegin"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                    @input="startChronometrer()"
                  >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          addInputClasses="pr-0"
                          class="w-100 float-left"
                          v-uppercase
                          placeholder="DD/MM/YYYY HH:mm"
                          :is-valid="hasError($v.dateBegin)"
                          v-model.trim="$v.dateBegin.$model"
                          @keypress="rightDate($event)"
                          @blur="$v.dateBegin.$touch()"
                          @input="startChronometrer()"
                          size="sm" 
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6" class="center-field">
              <div class="form-group form-row mb-0" rol="group">
                <label class="col-form-label-sm text-right col-sm-11 col-lg-4 mb-0">
                  {{$t('label.dateTo')}}
                </label>
                <div class="col-sm-12 col-lg-8 input-group">
                  <vue-datepicker
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="dateFinish"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                    @input="startChronometrer()"
                  >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          addInputClasses="pr-0"
                          class="w-100 float-left"
                          v-uppercase
                          placeholder="DD/MM/YYYY HH:mm"
                          :is-valid="hasError($v.dateFinish)"
                          v-model.trim="$v.dateFinish.$model"
                          @keypress="rightDate($event)"
                          @blur="$v.dateFinish.$touch()"
                          @input="startChronometrer()"
                          size="sm" 
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
              </div>
            </CCol>
          </CRow> 
        </div>
        <div class="col-sm-12 col-lg-auto text-right pl-0">
          <CButton
            color="watch"
            size="sm"
            class="mr-1"
            :disabled="isSubmit"
            v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}"
            @click="craneList()"
          >
            <CIcon name="cil-search" />
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
            @click="(dateBegin='', dateFinish='', clearChronometer())"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CRow>
      <CRow>
        <div class="col-sm-12 col-lg-11">
          <CRow>
            <CCol sm="12" lg="6" class="center-field">
              <CTextarea
                :label="$t('label.observation')"
                :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-8'}"
                v-uppercase
                v-model="$v.observation.$model"
                :is-valid="hasError($v.observation)"
                :invalid-feedback="errorMessage($v.observation)"                         
                @blur="$v.observation.$touch()"  
                :plaintext="false"
                :readonly="false"
                :lazy="(true,500)"
                size="sm"   
              >
              </CTextarea>
            </CCol>
          </CRow>
        </div>
      </CRow>
      <CRow>
        <div class="col-sm-12 col-lg-11">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="6">
              <CRow class="justify-content-end">
                <CCol sm="12" lg="10">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="computedCraneList"
                    :items-per-page="10"
                    pagination
                    :noItemsView="tableText.noItemsViewText"
                  >
                    <template #Checked="{ item }">
                      <td>
                        <CInputCheckbox
                          custom
                          :checked="item.Checked"
                          class="checkbook-filter ml-1"
                          @update:checked="addRemoveCrane(item)"
                        />
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="10" lg="6" style="align-items: center;display: flex;justify-content: center;">
              <div class="center-field text-center">
                <div>
                  <CRow>
                    <h3 class="timer-delay">{{dataChronometer(hour)}}:{{dataChronometer(min)}}:{{dataChronometer(sec)}}</h3>
                    <div class="ml-4 mt-3">
                      <CButton
                        outline
                        :color="checkColorChronometer"
                        @click="startChronometerClick()"
                        class="d-flex align-items-center"
                      >
                        <CIcon name="on" />&nbsp; {{timer !== null ? "OFF" :"ON" }}
                      </CButton>
                    </div>
                  </CRow>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </CRow>
      <template #footer>
        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template> 
    </CModalExtended>       
  </div>       
</template>
<script>
  import UpperCase from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import mixinServicio from '@/_mixins/servicio';
  import DetentionCraneValidations from '@/_validations/visitas/detentionCraneValidations';
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import moment from 'moment';
//Data
  function data() {
    return {
      showModal: false,
      isSubmit: false, 
      loadingOverlay: false,
      openDateStart: false,
      openDateEnd: false,
      isClickedButton: false,
      VesselCrane: [],
      dataCrane: [],

      previousDate: new Date(), 
      laterDate: new Date(),
      ValidPreviousDate: '',
      ValidLaterDate: '',

      titleModal: '',
      VisitCraneEventGpoId: '',
      dateBegin: '',
      dateFinish: '',
      observation: '',
      date: '',
      time: '',

      FgActVisitCraneDetail: 1,
     
      sec: 0,
      min: 0,
      hour: 0,
      timer: null,
      intervalList: [],
    }
  }

//methods
  function craneList() {
    try {
      this.isSubmit = true;
      this.loadingOverlay = true;
      this.$v.dateBegin.$touch();
      this.$v.dateFinish.$touch();
    
      if (this.$v.dateBegin.$error||this.$v.dateFinish.$error) {
        let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.dateBegin != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        if(this.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }  
        }
        if(this.dateBegin != '' && this.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.dateFinish)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
        throw this.$t('validation.TheDateFromIsRequired');
      }
      this.VesselCrane = [];
      let VisitCraneJson = !this.editModal ? {
        VisitId: this.visit,
        TransactionBegin: this.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.dateBegin) : '',
        TransactionFinish: this.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.dateFinish) : '',
      }:{
        VisitId: this.visit,
        TransactionBegin: this.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.dateBegin) : '',
        TransactionFinish: this.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.dateFinish) : '',
        VisitCraneEventGpoId: this.VisitCraneEventGpoId,
      }
      this.$http.post("VisitCraneDetentionApply-list", VisitCraneJson, { root: 'VisitCraneJson' })
      .then(response => {
        let CraneList = response.data.data
        if (Object.entries(CraneList[0]).length > 1) {
          this.dataCrane = CraneList;
          this.dataCrane.map(Crane => {
            if (Crane.FgActive) {
              this.VesselCrane.push(Crane);
            }
          })
        }else{
          this.dataCrane = [];
        }
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay = false;
      }).then(() => {
        this.isSubmit = false;
        this.loadingOverlay = false;
      });
    } catch (error) {
      this.isSubmit = false;
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
    
  }

  function functionDateStart(event) {
    if (event) {
      this.openDateStart = event;
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function getDateRange(){
   
    let currentDate = new Date();
    if(this.itinerarySelected.Ata!=undefined){ 
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    }

    if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
    }

    if(this.itinerarySelected.Atd!=undefined){  
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
    }

    if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
    }
  }  

  function resetForm() {
    this.dataCrane = [];
    this.VesselCrane = [];
    this.date = '';
    this.time = '';
    this.observation = '';
    this.dateFinish = '';
    this.dateBegin = '';
    this.ValidPreviousDate = '',
    this.ValidLaterDate = '',
    this.FgActVisitCraneDetail = 1;
    this.openDateStart = false;
    this.openDateEnd = false;
    this.isClickedButton = false;
    this.sec = 0;
    this.min = 0;
    this.hour = 0;
    this.timer = null; 
    this.intervalList = [];
    this.isSubmit = false;
    this.loadingOverlay = false;
    this.titleModal = '', 
    this.$v.$reset();
  }

  function closeModal() {
    this.showModal=false;
    this.playChronometer(false);
    this.playingChronometer(false);
    this.resetForm();
    this.openDate = false;
    this.$emit('closeModal');
  }

  function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.$touch();
      if (this.$v.$error) {
        let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
          || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.dateBegin != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        if(this.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        if(this.dateBegin != '' && this.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.dateFinish)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
        throw this.$t('label.errorsPleaseCheck');
      }

      let metodo = this.editModal ? 'PUT' : 'POST';
      let ruta = this.editModal ? 'VisitCraneDetention-update' : 'VisitCraneDetention-insert'; 
      
      let TotalGrane = [];
      let CraneJson = [];
      if (this.editModal) {
        TotalGrane = [...this.modal.VisitCraneDetailJson, ...this.VesselCrane];
        TotalGrane.map(item => {
          if (!CraneJson.some(VesselCrane => {return VesselCrane.VisitCraneId == item.VisitCraneId})) {
            CraneJson.push(item);
          }
        });
      }

      let VisitCraneJson = !this.editModal ? this.VesselCrane.map(item =>{
        return {
          VisitCraneId: item.VisitCraneId,
          VisitId: this.visit,
          TransactionBegin: this.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.dateBegin) : '',
          TransactionFinish: this.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.dateFinish) : '',
          Observation: this.observation,
        }
      }) : {
        VisitCraneEventGpoId: this.VisitCraneEventGpoId,
        VisitId: this.visit,
        TransactionBegin: this.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.dateBegin) : '',
        TransactionFinish: this.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.dateFinish) : '',
        Observation: this.observation,
        Status: 1,
        VisitCraneDetailJson: CraneJson.map(item =>{
          let ValidatePreviousCrane = this.modal.VisitCraneDetailJson.find(VisitCraneDetailJson => {
            return VisitCraneDetailJson.VisitCraneId == item.VisitCraneId;
          })
          let ValidateCurrentCrane = this.VesselCrane.some(VesselCrane => {
            return VesselCrane.VisitCraneId == item.VisitCraneId;
          })
          return {
            VisitCraneDetailId: ValidatePreviousCrane ? ValidatePreviousCrane.VisitCraneDetailId : '',
            VisitCraneId: item.VisitCraneId,
            Status: ValidateCurrentCrane ? 1 : 0,
          }
        })
      }

      this.$http.ejecutar(metodo, ruta, VisitCraneJson, { root: 'VisitCraneJson' })
        .then(response => {
            this.loadingOverlay = false;
            if (this.editModal) {
              if (response.data.data[0].StatusItem != 200 && response.data.data[0].StatusItem != null) {
                let _lang = this.$i18n.locale;
                let ErrorJson = response.data.data[0].ResponseJson.map(item =>{
                  return _lang=='en' ? item.ErrorMessageEn : item.ErrorMessageEs;
                });
                this.notifyError({text: ErrorJson.join(',')});
                this.isSubmit = false;
                this.loadingOverlay = false;
              }else{
                this.NotifyMultipleCrane(response.data.data[0]);
              }
            } else {
              this.NotifySimpleCrane(response.data.data);
            }
        }).catch(err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
            });
            this.isSubmit = false;
            this.loadingOverlay = false;
        }).then(() => {
            this.isSubmit = false;
            this.loadingOverlay = false;
        }); 

    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.notifyError({ text: e });
    }
  }

  function datesCompare(valueDate, valueTime ){
    let date, time;
    date =valueDate.split("/");
    time =valueTime.split(":");
    return date[2]+date[1]+date[0]+time[0]+time[1];
  }

  function dataChronometer(number){
    return number.toString().padStart(2,0)
  }

  function playChronometer(value){
    if(value){
      if(this.dateBegin == ''){
        let dateNow = new Date();
        let currentDate = DateFormater.formatTimeZoneToDateTime(dateNow);

        this.sec = 0;
        this.min = 0;
        this.hour = 0;

        this.dateBegin = currentDate;

        this.$v.dateBegin.$touch();
      }
      if(this.timer === null){
        this.playingChronometer(true);
        this.timer = setInterval(()=> this.playingChronometer(true), 1000);
      }
      else{
          if(this.isClickedButton){
            clearInterval(this.timer);
            this.timer = null;
            this.pauseChronometer();
          }
          
        }
    } 
    else {
      this.playingChronometer(false);

      clearInterval(this.timer);
      this.timer = null;
      this.pauseChronometer(); 
    }
  }

  async function playingChronometer(valor){
    if(valor){
      this.sec++
      if(this.sec >= 59){
        this.sec = 0;
        this.min++;
      }
      if(this.min >= 59){
        this.min = 0;
        this.hour++;
      }

    }
  }

  function NotifyMultipleCrane(response) {
    let CraneError = response.VisitCraneDetailJson.some(item => item.StatusItem != 200);
    let CraneResponse = response.VisitCraneDetailJson.map((item) => {
      if (this.VesselCrane.some(VesselCrane => VesselCrane.VisitCraneId == item.VisitCraneId)) {
        if (item.StatusItem == 200) {
          return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #2eb85c!important;" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg>
            <b>${item.CraneAlias}</b> ${CraneError ? '' : this.$t('label.RecordUpdatedSuccessFully')}</div>`;
        }else{
          let _lang = this.$i18n.locale;
          let ErrorJson = item.ResponseJson.map(ResponseJson =>{
            return _lang=='en' ? ResponseJson.ErrorMessageEn : ResponseJson.ErrorMessageEs;
          });
          return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #e55353!important;" class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg>
            <b>${item.CraneAlias}</b> ${ErrorJson.join(',')}</div>`;
        }
      }
    })
    this.$swal.fire({
      title: this.$t('label.detentionCrane'),
      html: CraneResponse.join(''),
      icon: 'warning',
      padding: '10px',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      confirmButtonColor: '#42AA91',
      confirmButtonText: 'CONFIRMAR',
    }).then((result) => {
      if (result.isConfirmed) {
        if (!CraneError) {
          this.modalActive = false;
          this.playChronometer(false);
          this.playingChronometer(false);
          this.closeModal();
          this.$emit('child-refresh', true);
        }
      }
    })
  }

  function NotifySimpleCrane(response) {
    let CraneError = response.some(item => item.StatusItem != 200);
    let CraneResponse = response.map((item) => {
      if (item.StatusItem == 200) {
        return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #2eb85c!important;" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg>
          <b>${item.CraneAlias}</b> ${this.$t('label.RecordUpdatedSuccessFully')}</br></div>`;
      }else{
        let _lang = this.$i18n.locale;
        let ErrorJson = item.ResponseJson.map(ResponseJson =>{
          return _lang=='en' ? ResponseJson.ErrorMessageEn : ResponseJson.ErrorMessageEs;
        });
        return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #e55353!important;" class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg>
          <b>${item.CraneAlias}</b> ${ErrorJson.join(',')}</br></div>`;
      }
    })
    this.$swal.fire({
      title: this.$t('label.detentionCrane'),
      html: CraneResponse.join(''),
      icon: 'warning',
      padding: '10px',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      confirmButtonColor: '#42AA91',
      confirmButtonText: 'CONFIRMAR',
    }).then((result) => {
      if (result.isConfirmed) {
        if (!CraneError) {
          this.modalActive = false;
          this.playChronometer(false);
          this.playingChronometer(false);
          this.closeModal();
          this.$emit('child-refresh', true);
        }
      }
    })
  }
  
  function  pauseChronometer(){
    this.intervalList.push(`${this.dataChronometer(this.hour)}:${this.dataChronometer(this.min)}:${this.dataChronometer(this.sec)}`);
    let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.dateBegin);
    let StartDate = DateFormater.formatDateTimeWithSlashToOnlyDate(this.dateBegin);
    var var1 = (this.hour*3600+this.min*60)*1000; 
    var arrDateStart = StartDate.split("/");
    var fecha1 = new Date(arrDateStart[1]+'/'+arrDateStart[0]+'/'+arrDateStart[2]+' '+timeBegin);
    fecha1 = fecha1.getTime();
    var newDateObj = new Date(fecha1 + var1);

    this.dateFinish = "";

    this.dateFinish = DateFormater.formatTimeZoneToDateTime(newDateObj);

    this.$v.dateFinish.$touch();
  }

  function clearChronometer(){
    if(this.timer !== null){
      clearInterval(this.timer);
      this.timer = null;
    }

    this.sec = 0;
    this.min = 0;
    this.hour = 0;
    this.clearIntervalList();
  }

  function clearIntervalList(){
    this.intervalList = []
  }

  async function startChronometrer(){
    try {
      this.VesselCrane = [];
      this.dataCrane = [];
      let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
      if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
        || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
          throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
        || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
          throw this.$t('label.ErrorEtaPleaseCheck');
      }
      if ((DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
        || this.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if(this.dateBegin != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
      if(this.dateFinish != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }  
      }
      if(this.dateBegin != '' && this.dateFinish != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.dateFinish)) {
          throw this.$t('label.ErrorFromToPleaseCheck');
        }
      }
    } catch (error) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
      this.clearChronometer();
    }

    if(this.dateBegin !== '' && this.dateFinish != ''){
      if( moment(this.dateBegin,'DD/MM/YYYY HH:mm').isBefore(moment(this.dateFinish,'DD/MM/YYYY HH:mm') )){
        let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.dateBegin);
        let timeFinish = DateFormater.formatDateTimeWithSlashToOnlyTime(this.dateFinish);
        let totalDate = DateFormater.datesDifferenceInHours(DateFormater.formatDateTimeWithSlashToOnlyDate(this.dateBegin), 
          DateFormater.formatDateTimeWithSlashToOnlyDate(this.dateFinish), timeBegin, timeFinish);
        totalDate = totalDate.split(":");
        this.sec = 0;
        this.min = totalDate[1];
        this.hour = totalDate[0];

        if(this.timer != null){
          this.isClickedButton = false;
          this.playChronometer(false); 
        }else{
          clearInterval(this.timer);
          this.timer = null;
          this.pauseChronometer();
        }
      } 
    }else{
      if(this.dateFinish == ''){
        let dateNow = new Date();
        let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
        let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
        let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.dateBegin);
        let endTime2 = (this.itinerarySelected.Atd==undefined  && this.itinerarySelected.Atd==null) ?
          currentTime :
          DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
                
        let endDate2 = (this.itinerarySelected.Atd==undefined  && this.itinerarySelected.Atd==null) ?
          currentDate :
          DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));

        let totalDate = DateFormater.datesDifferenceInHours(DateFormater.formatDateTimeWithSlashToOnlyDate(this.dateBegin), 
          endDate2, timeBegin, endTime2);

        totalDate = totalDate.split(":");
        this.sec = 0;
        this.min = totalDate[1];
        this.hour = totalDate[0];
        this.isClickedButton = false;
        this.playChronometer(true);
      }else{
        this.clearChronometer();
      }
    }
  }
  
  function addRemoveCrane(item) {
    let Crane = this.VesselCrane.some((VesselCrane) => VesselCrane.VisitCraneId == item.VisitCraneId);
    if (!Crane) {
      //ADD
      this.VesselCrane.push(item)
    }else{
      //DELETE
      this.VesselCrane = this.VesselCrane.filter((VesselCrane) => VesselCrane.VisitCraneId !== item.VisitCraneId);
    }
  }

  function startChronometerClick(){
    this.isClickedButton = true;
    this.playChronometer(true); 
  }

  //computed
  function craneOptions(){
    return this.dataCrane;
  }

  function checkColorChronometer(){
    return this.timer == null ? "add" : "wipe" ;
  }

  function computedCraneList() {
    return this.dataCrane.map((item) => {
      let Checked = this.VesselCrane.some(VesselCrane => VesselCrane.VisitCraneId == item.VisitCraneId)
      return {
        ...item,
        Checked: Checked,
      };
    });
  }

function fields(){
  return [
    { label: '', key: 'Checked', _classes: 'text-center', _style: 'width:3%;', filter: false},
    { label: this.$t('label.crane'), key: 'CraneAlias', _classes: 'text-center', _style: 'width:97%;',},
  ];
}
  
  export default {
    name: 'modal-detention-crane',
    mixins: [ GeneralMixin, mixinServicio, ModalMixin ],
    props: {
      modal: null,
      editModal: Boolean,
    },
    data,
    validations(){
      return DetentionCraneValidations(this.dateBegin, this.dateFinish, this.ValidLaterDate, this.ValidPreviousDate, this.itinerarySelected);
    },
    directives: UpperCase,
    methods: {
      addRemoveCrane,
      craneList,
      functionDateStart,
      validateDateRange,
      resetForm,
      submit,
      datesCompare,
      getDateRange,
      closeModal,
      NotifyMultipleCrane,
      NotifySimpleCrane,
      dataChronometer, 
      playChronometer, 
      playingChronometer, 
      pauseChronometer, 
      clearChronometer, 
      clearIntervalList,
      startChronometrer,
      startChronometerClick,
    },
    computed: {
      fields,
      computedCraneList,
      craneOptions,
      checkColorChronometer,
      ...mapState({
        visit: state => state.visitas.VisitId,
        itineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
        StowagePlanningId: state => state.visitas.StowagePlanningId,
      }),
    },
    watch: {
      modal: async function(){
        if(this.modal){
          this.showModal = true;  
          this.getDateRange(); 
          if (this.modal==true) {
            this.titleModal = this.$t('label.nueva')+' '+this.$t('label.detentionCrane');
          } else {
            if(this.editModal){
              this.loadingOverlay = true;
              let title = this.modal.VisitCraneDetailJson[0].CraneAlias;
              this.modal.VisitCraneDetailJson.map(item => {
                if (this.modal.VisitCraneDetailJson[0].VisitCraneId!=item.VisitCraneId) {
                  title = title+' - '+item.CraneAlias;
                }
              })
              this.titleModal = this.$t('label.edit')+' '+this.$t('label.detentionCrane')+': '+title;
              this.VisitCraneEventGpoId = this.modal.VisitCraneEventGpoId;
              this.dateBegin = this.modal.TransactionBegin ? DateFormater.formatDateTimeWithSlash(this.modal.TransactionBegin) : '';
              this.dateFinish = this.modal.TransactionFinish ? DateFormater.formatDateTimeWithSlash(this.modal.TransactionFinish) : '';
              this.observation = this.modal.Observation;
              this.FgActVisitCraneDetail = this.modal.FgActVisitCraneDetail ? 1 : 0;
              await this.startChronometrer();
              await this.craneList();
              this.$v.$touch();
            }
          }
        }
      },
    }
  }
</script>

<style lang="scss" scope>
.isValid-multi .multiselect__tags{
  border-color: #2eb85c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232eb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-left: calc(1.5em + 0.75rem) !important;
}

.invalid-multi .multiselect__tags{
  border-color: #e55353;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-left: calc(1.5em + 0.75rem) !important;
}

.timer-delay{
  color: rgb(19, 16, 16);
  font-size: 45px;
  margin-left: 0.5rem !important;

}
.btns{
  margin-top: 155px;
  display: flex;
}

.interval{
  color: rgb(82, 27, 153);
  flex: 1;
  width: 420px;
  margin-top: 15px;
}
.interval ul{
  text-align: center;
}
.interval ul li{
  list-style: none;
  background-color: rgb(70,70,70);
  padding: 15px;
  margin-bottom: 10px;
}

</style>