import { required, maxLength, } from "vuelidate/lib/validators";
import { DateFormater } from "@/_helpers/funciones";
import {  onlyAlphanumeric4 } from '@/_validations/validacionEspeciales.js';
    export default (fromDay, toDay, laterDate, previousDate, itinerarySelected) => {
        return {
                VesselCrane: { required },
                dateBegin: { required, TimeValidate(value){
                        if (itinerarySelected.Atd!=undefined) {
                            return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
                        } else {
                            let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
                            return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                DateFormater.formatDateTimeWithoutSlash(value) <= currentDate
                        }
                    }, RangeValidate (value) {
                        if (toDay) {
                            return DateFormater.formatDateTimeWithoutSlash(value) < DateFormater.formatDateTimeWithoutSlash(toDay)
                        }else{
                            return true
                        }  
                    }
                },
                dateFinish: { TimeValidate(value){
                        if (value) {
                            if (itinerarySelected.Atd!=undefined){
                                return DateFormater.formatDateTimeWithoutSlash(value) <= laterDate &&
                                    DateFormater.formatDateTimeWithoutSlash(value) >= previousDate
                            }else{
                                let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
                                return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                    DateFormater.formatDateTimeWithoutSlash(value) <= currentDate
                            }
                        }else{
                            return true;
                        }
                    }, RangeValidate (value) {
                        if (fromDay&&value) {
                            return DateFormater.formatDateTimeWithoutSlash(value) > DateFormater.formatDateTimeWithoutSlash(fromDay)
                        }else{
                            return true
                        }  
                    } 
                },
                observation: { maxLength: maxLength(250), onlyAlphanumeric4 },
                date: {  },
                time: {  },
        }
    }